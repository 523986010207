import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'
import { IKService } from "../services/ik_service"

const windowGlobal = typeof window !== 'undefined' && window

var ValidateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const EmailError = () => {
  return (
    <div className="warn">Invalid email address!</div>
  );
}
const NameError = () => {
  return (
    <div className="warn">Invalid name!</div>
  );
}

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const code = urlParams.get('code');
    const currency = urlParams.get('currency');
    this.state = {
      product: null,
      loading: true,
      product_code: code,
      name: '',
      email: '',
      error: '',
      currency
    };
  }

  componentDidMount () {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3";
    script.async = true;
    document.body.appendChild(script);
    IKService.get_product(this.state.product_code).then(data => {      
      console.log(data);
      this.setState({product: data[0], loading: false, currency: this.state.currency});
    }).catch(err => {});
  }

  submit() {    
    if(!ValidateEmail(this.state.email)) {
      this.setState({error: EmailError()});
      return;
    }
    if(this.state.name.length < 3) {
      this.setState({error: NameError()});
      return;
    }
    var args = {
      code: this.state.product_code,
      currency: this.state.currency,
      email: this.state.email,
      name: this.state.name,
    };
    console.log(args);
    IKService.checkout(args).then(data=>{      
      console.log(data);      
      var stripe = windowGlobal.Stripe(data.public_key);
      stripe.redirectToCheckout({
        sessionId: data.session_id
      }).then(function (result) {
        alert('Failed to connect to payment provider. Please contact us directly');
      });
    }).catch(function (err) {
      alert('Paymnet error. ' + err);
    });
  };

  handleNameInput = event => {
    this.setState({ name: event.target.value, error:'' });
  };
  handleEmailInput = event => {
    this.setState({ email: event.target.value, error:'' });
  };

  render() {

    if(this.state.loading) {
      return (
        <Layout>
          <Helmet defer={false}>
            <meta name="robots" content="NOINDEX, NOFOLLOW" />
          </Helmet>
          <div className="text-center">loading...</div>
        </Layout>
      )
    }    
    const price = this.state.product.price[this.state.currency].value.toFixed(2);

    return(
      <Layout>
        <Helmet defer={false}>
          <meta name="robots" content="NOINDEX, NOFOLLOW" />
        </Helmet>
        <section>
          <div className="container">
            <h2>{this.state.product.title}</h2>
            <p>{this.state.product.description}</p>
            <div className="price-box">
              <div className="price-info"><span className="app-currency-symbol"></span>{price} {this.state.currency}</div>
            </div>
            <form className="form text-center">
              <div id="Error">{this.state.error}</div>
              <label>Amount: {price} {this.state.currency}</label>
              <p><input name="Name" onChange={this.handleNameInput} id="name" type="text" placeholder="Name" /></p>
              <p><input name="Email" onChange={this.handleEmailInput} id="email" type="email" placeholder="Email" /></p>
              <p><br /><span className="buy-button" role="button" tabIndex={0} onClick={this.submit.bind(this)} onKeyUp={this.submit.bind(this)}>BUY NOW</span></p>
            </form>
            <p className="note top-spacer text-center">
              Please note - if you are buying digital goods, they are none refundable items due to there very nature.
              If you are happy to proceed the follow the instructions above. Payments are usually instant but if there is a delay be patient and allow a couple of minutes before closing or refreshing the page.
              Upon completion you should be directed to a confirmation page with all the relevant details. You will also be emailed a confirmation with the relavent information attached. Thanks...
            </p>
          </div>
        </section>
      </Layout>
    );
  }  
}
export default CheckoutPage